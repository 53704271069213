<!--<template>-->
<!--    <gptDataFetcher/>-->
<!--</template>-->

<!--<script lang="ts">-->
<!--import { defineComponent } from 'vue';-->
<!--import gptDataFetcher from '@/components/gptDataFetcher.vue'; // @ is an alias to /src-->

<!--export default defineComponent({-->
<!--  name: 'HomeView',-->
<!--  components: {-->
<!--    gptDataFetcher,-->
<!--  },-->
<!--});-->
<!--</script>-->

<!--<template>-->
<!--  <a-mentions-->
<!--      v-model:value="value"-->
<!--      :prefix="['相', '#']"-->
<!--      :options="options"-->
<!--      @search="onSearch"-->
<!--  ></a-mentions>-->

<!--  <div id="app">-->

<!--    <pre v-if="response">{{ displayedResponse }}</pre>-->
<!--  </div>-->

<!--</template>-->
<!--<script lang="ts" setup>-->
<!--import { computed, ref } from 'vue';-->
<!--// import axios from 'axios';-->

<!--const response = ref('');-->
<!--const displayedResponse = ref('');-->
<!--//-->


<!--const MOCK_DATA: Record<string,string[]> = {-->
<!--  '相': ['湘', '箱', '想'],-->
<!--  '#': ['1.0', '2.0', '3.0'],-->
<!--};-->
<!--const prefix = ref<string>('相');-->
<!--const value = ref<string>('鋳物工場内の2階倉庫から1階作業場に通じる階段において、木相(65×45×20cm)を倉庫から搬出作業中、後ろ向きに階段を下っていたため、足を踏み外し、約1.7m下に転落し、左足首を捻挫した。');-->
<!--const options = computed(() => {-->
<!--  return (MOCK_DATA[prefix.value] || []).map(value => ({-->
<!--    key: value,-->
<!--    value,-->
<!--    label: value,-->
<!--  }));-->
<!--});-->

<!--const onSearch = (_:any, val:any) => {-->
<!--  console.log(_, val);-->
<!--  prefix.value = val;-->
<!--};-->
<!--</script>-->

<!--<template>-->
<!--  <a-button type="primary" @click="handleOpen(true)">Begin Tour</a-button>-->

<!--  <a-divider />-->

<!--  <a-space>-->
<!--    <a-button ref="ref1">Upload</a-button>-->
<!--    <a-button ref="ref2" type="primary">Save</a-button>-->
<!--    <a-button ref="ref3"><EllipsisOutlined /></a-button>-->
<!--  </a-space>-->

<!--  <a-tour v-model:current="current" :open="open" :steps="steps" @close="handleOpen(false)" />-->
<!--</template>-->
<!--<script lang="ts" setup>-->
<!--import { ref, createVNode } from 'vue';-->
<!--import { EllipsisOutlined } from '@ant-design/icons-vue';-->
<!--import type { TourProps } from 'ant-design-vue';-->

<!--const open = ref<boolean>(false);-->

<!--const ref1 = ref(null);-->
<!--const ref2 = ref(null);-->
<!--const ref3 = ref(null);-->
<!--const current = ref(0);-->
<!--const steps: TourProps['steps'] = [-->
<!--  {-->
<!--    title: 'Upload File',-->
<!--    description: 'Put your files here.',-->
<!--    cover: createVNode('img', {-->
<!--      alt: 'tour.png',-->
<!--      src: 'https://user-images.githubusercontent.com/5378891/197385811-55df8480-7ff4-44bd-9d43-a7dade598d70.png',-->
<!--    }),-->
<!--    target: () => ref1.value && ref1.value.$el,-->
<!--  },-->
<!--  {-->
<!--    title: 'Save',-->
<!--    description: 'Save your changes.',-->
<!--    target: () => ref2.value && ref2.value.$el,-->
<!--  },-->
<!--  {-->
<!--    title: 'Other Actions',-->
<!--    description: 'Click to see other actions.',-->
<!--    target: () => ref3.value && ref3.value.$el,-->
<!--  },-->
<!--];-->

<!--const handleOpen = (val: boolean): void => {-->
<!--  open.value = val;-->
<!--};-->
<!--</script>-->

<template>
  <a-button type="primary" value="large" @click="handleOpen(true)">ガイド開始</a-button>

  <a-divider />

  <a-space>
    <a-button ref="ref1">参照<EllipsisOutlined /></a-button>
    <a-button ref="ref2" type="primary">OCRで認識</a-button>
    <a-button ref="ref3">決裁書作成</a-button>
  </a-space>

  <a-tour :open="open" :mask="false" type="primary" :steps="steps" @close="handleOpen(false)" />
</template>
<script lang="ts" setup>
import { ref, createVNode } from 'vue';
import { EllipsisOutlined } from '@ant-design/icons-vue';
import type { TourProps } from 'ant-design-vue';
const open = ref<boolean>(false);

const ref1 = ref(null);
const ref2 = ref(null);
const ref3 = ref(null);

const steps: TourProps['steps'] = [
  {
    title: '画像ファイルのプレビュー表示',
    description: '画像を選択してプレビュー',
    cover: createVNode('img', {
      alt: 'img.png',
      src: 'https://i.imgur.com/mmZRG4j.png',
    }),
    target: () => ref1.value && ref1.value.$el,
  },
  {
    title: 'AI-OCR',
    description: '文字を認識してデジタルデータに変換',
    cover: createVNode('img', {
      alt: 'ocr.png',
      src: 'https://i.imgur.com/7MIZjXQ.png',
    }),
    target: () => ref2.value && ref2.value.$el,
  },
  {
    title: '決裁書作成',
    description: '認識結果による決裁書を作成',
    cover: createVNode('img', {
      alt: 'book.png',
      src: 'https://i.imgur.com/PWwKFxg.png',
    }),
    target: () => ref3.value && ref3.value.$el,
  },
];

const handleOpen = (val: boolean): void => {
  open.value = val;
};
</script>



